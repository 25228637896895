import { useThemeContext } from 'lib/context/themeContext'
import Image from 'next/image'
import Link from 'next/link'
import { ImageType } from 'views/types'

interface GalleryCardProps {
  image?: ImageType
  artist?: string
  title: string
  description?: string
  spots?: string
  slug?: string
}

const GalleryCard = ({
  image,
  artist,
  title,
  description,
  spots,
  slug,
}: GalleryCardProps) => {
  const { handleInviteDrawerOpen } = useThemeContext()

  return (
    <div className="flex flex-col bg-white rounded-2xl shadow-gallery-card mt-8 md:mt-0 first:mt-0 transition-all duration-300 hover:-translate-y-1 hover:drop-shadow-md overflow-hidden">
      <div className="md:h-72 p-4 bg-zinc-100 flex items-center">
        {image && (
          <div className="h-full">
            <Image
              src={image.src}
              height={image.height}
              width={image.width}
              alt={image?.alt || title}
              className="object-contain h-full"
            />
          </div>
        )}
      </div>

      <div className="p-4 flex flex-1 flex-col">
        {artist && (
          <div className="text-sm text-freeport-blue-dark uppercase">
            {artist}
          </div>
        )}

        <h3 className="font-bold text-xl text-freeport-blue-new text-ellipsis tracking-tighter leading-tight line-clamp-3">
          {title}
        </h3>

        {description && (
          <p className="mt-3 mb-4 text-sm text-freeport-blue-navy text-ellipsis tracking-tighter leading-normal line-clamp-2">
            {description}
          </p>
        )}

        {spots && (
          <p className="mb-8 text-sm text-freeport-blue-navy text-ellipsis tracking-tighter leading-normal line-clamp-2">
            {spots}
          </p>
        )}

        {/* <button
          className="mt-auto px-4 py-2 font-semibold text-sm backface-visibility-hidden whitespace-nowrap flex justify-center transform rounded-full text-white font-medium bg-freeport-blue-base transition hover:scale-105 focus:outline-none active:bg-opacity-80 w-auto rounded-full shadow-sm cursor-pointer"
          onClick={handleInviteDrawerOpen}
        >
          Learn more
        </button> */}
        <Link
          href={`/art/${slug}`}
          className="mt-auto px-4 py-2 font-semibold text-sm backface-visibility-hidden whitespace-nowrap flex justify-center transform text-white bg-freeport-blue-base transition hover:scale-105 focus:outline-none active:bg-opacity-80 w-auto rounded-full shadow-sm cursor-pointer"
        >
          View Details
        </Link>
      </div>
    </div>
  )
}

export { GalleryCard }
