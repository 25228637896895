import { QuestionMarkCircleSmTransparent } from 'components/icons/app/QuestionMarkCircleSmTransparent'
import { PopoverContent } from 'components/utilities/PopoverContent'
import Link from 'next/link'
import { useState } from 'react'
import { Popover } from 'react-tiny-popover'

const WarholPriceStat = () => {
  const [showPopover, setShowPopover] = useState(false)

  return (
    <div
      className="mt-12"
      onMouseEnter={() => setShowPopover(true)}
      onMouseLeave={() => setShowPopover(false)}
    >
      <div className="inline-block relative cursor-default text-freeport-blue-new text-freeport-section-title-md font-title">
        134%
        <QuestionMarkCircleSmTransparent className="absolute -top-1 -right-8 md:-right-4 lg:-right-4" />
      </div>
      <div>
        <Popover
          isOpen={showPopover}
          positions={['bottom']}
          containerClassName="z-20"
          content={
            <PopoverContent>
              <p className="font-medium">
                We compared all Andy Warhol works sold during Q4 2022 for
                greater than $100,000 to the midpoint of their estimated sales
                price. Sales price excludes auction fees.
              </p>
              <p className="mt-3">
                Source:{' '}
                <Link
                  href="https://www.artprice.com/artist/30269/andy-warhol"
                  title=""
                  className="font-semibold hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  artprice.com
                </Link>
              </p>
            </PopoverContent>
          }
        >
          <div className="cursor-default text-freeport-blue-base text-sm">
            Warhol prices fetched at auction vs estimate
          </div>
        </Popover>
      </div>
    </div>
  )
}

export { WarholPriceStat }
