import { Container } from 'components/design-system/Container'
import Link from 'next/link'
import { Ref } from 'react'
import { GalleryVideo } from 'views/home/GallerySection/GalleryVideo'
import { WarholCollectionSection } from './WarholCollectionSection'

interface GallerySectionProps {
  forwardedRef?: Ref<HTMLDivElement>
  warholCollectionProps?: any
  title?: string
  description?: string
  ctaLinkText?: string
  ctaLinkUrl?: string
}

const GallerySection = ({
  forwardedRef,
  warholCollectionProps,
  title,
  description,
  ctaLinkText,
  ctaLinkUrl,
}: GallerySectionProps) => {
  return (
    <section className="bg-freeport-blue-dark text-white">
      <Container className="mt-0 mb-0">
        <div className="relative z-10 mt-14 md:mt-24 md:min-h-[40rem] flex flex-col justify-center">
          <GalleryVideo
            src="/videos/gallery-walkthrough.mp4"
            poster="/images/gallery-video-poster.png"
            ariaLabel="freeport Gallery preview"
            className="rounded-[2rem]"
          />

          <div className="relative w-full h-full mt-8 pl-0 md:pl-8 pr-8 py-12 md:mt-0 lg:w-1/2">
            <h2 className="text-white text-freeport-section-title-md font-title">
              {title}
            </h2>
            <p className="mt-6 text-xl">{description}</p>

            <Link
              href={ctaLinkUrl as string}
              className="mt-7 px-5 py-3 inline-block font-bold backface-visibility-hidden whitespace-nowrap transform md:self-center rounded-full text-freeport-blue-dark bg-freeport-blue-light transition hover:scale-105 focus:outline-none active:bg-opacity-80 shadow-sm"
            >
              {ctaLinkText}
            </Link>
          </div>
        </div>

        <div ref={forwardedRef}>
          <WarholCollectionSection {...warholCollectionProps} />
        </div>
      </Container>
    </section>
  )
}

GallerySection.defaultProps = {
  title: 'Display iconic art in your own digital gallery',
  description:
    'Collecting comes to life on Freeport. Once you’ve made an investment, you can add the art to your own 3D gallery - and share it with friends. You can also connect a wallet to view your favorite NFTs.',
  ctaLinkText: 'Explore the gallery →',
  ctaLinkUrl: '/gallery',
}

export { GallerySection }
