interface Props {
  className?: string
}

const QuestionMarkCircleSmTransparent = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.25C3.71875 0.25 0.25 3.75 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.25 15.75 15.75 12.2812 15.75 8C15.75 3.75 12.25 0.25 8 0.25ZM8 14.25C4.53125 14.25 1.75 11.4688 1.75 8C1.75 4.5625 4.53125 1.75 8 1.75C11.4375 1.75 14.25 4.5625 14.25 8C14.25 11.4688 11.4375 14.25 8 14.25ZM11.3438 6.28125C11.3438 4.71875 9.6875 3.5 8.15625 3.5C6.6875 3.5 5.75 4.125 5.03125 5.21875C4.90625 5.375 4.9375 5.59375 5.09375 5.71875L5.96875 6.375C6.125 6.5 6.375 6.46875 6.5 6.3125C6.96875 5.71875 7.3125 5.375 8.03125 5.375C8.59375 5.375 9.28125 5.71875 9.28125 6.28125C9.28125 6.6875 8.9375 6.875 8.375 7.1875C7.75 7.5625 6.90625 8 6.90625 9.125V9.375C6.90625 9.59375 7.0625 9.75 7.28125 9.75H8.6875C8.90625 9.75 9.0625 9.59375 9.0625 9.375V9.1875C9.0625 8.40625 11.3438 8.375 11.3438 6.28125ZM9.3125 11.5C9.3125 10.7812 8.71875 10.1875 8 10.1875C7.25 10.1875 6.6875 10.7812 6.6875 11.5C6.6875 12.25 7.25 12.8125 8 12.8125C8.71875 12.8125 9.3125 12.25 9.3125 11.5Z"
        fill="#B2BBD6"
      />
    </svg>
  )
}

export { QuestionMarkCircleSmTransparent }
