import { motion, useScroll, useTransform } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { appearIn, firstColVariant, secondColVariant } from './framer'

interface HeroSectionProps {
  handleScrollTo?: () => void
  headline?: string
  title?: string
  description?: string
  buttonText?: string
  buttonLink?: string
}

const MickJagger = {
  url: '/images/marketing/artwork/mick-jagger-header.jpg',
  width: 839,
  height: 1247,
  alt: '"Mick Jagger (1967)" by Andy Warhol',
}

const Marilyn = {
  url: '/images/marketing/artwork/marilyn-header.jpg',
  width: 838,
  height: 838,
  alt: '"Marilyn (1967)" by Andy Warhol',
}

const RebelWithoutCause = {
  url: '/images/marketing/artwork/rebel-without-a-cause-header.jpg',
  width: 838,
  height: 838,
  alt: '"Rebel Without a Cause (1985)" by Andy Warhol',
}

const DoubleMickey = {
  url: '/images/marketing/artwork/double-mickey-header.jpg',
  width: 835,
  height: 592,
  alt: '"Double Mickey (1981)" by Andy Warhol',
}

const HeroSection = ({
  handleScrollTo,
  headline,
  title,
  description,
  buttonText,
  buttonLink,
}: HeroSectionProps) => {
  const heroGallery = [
    RebelWithoutCause,
    DoubleMickey,
    MickJagger,
    RebelWithoutCause,
    Marilyn,
    MickJagger,
    Marilyn,
    DoubleMickey,
    MickJagger,
    RebelWithoutCause,
  ]

  const firstCol = heroGallery.slice(0, Math.ceil(heroGallery.length / 2))
  const secondCol = heroGallery.slice(Math.ceil(heroGallery.length / 2))

  const { scrollYProgress } = useScroll()

  const top = useTransform(scrollYProgress, [0, 1], [0, 3000])
  const router = useRouter()

  return (
    <section className="overflow-hidden flex items-center relative h-[calc(100vh_-_80px)] min-h-[35rem] bg-gradient-to-t from-freeport-blue-400 via-freeport-blue-300 to-freeport-blue-200">
      <div className="w-full h-full mx-auto max-w-screen-xl px-8 sm:pl-16 pb-24 md:pb-16 md:mt-28 mb-8 flex flex-col justify-center md:justify-center">
        <motion.div
          key="parent"
          style={{ top }}
          transition={{
            type: 'spring',
            staggerChildren: 0.1,
            delayChildren: 0.3,
          }}
          className="grid md:grid-cols-2 gap-4 relative"
        >
          <motion.div
            transition={{
              staggerChildren: 0.1,
              delayChildren: 0.3,
            }}
            initial="hidden"
            animate="visible"
            className="pr-4 md:pr-24 lg:pr-40 2xl:pr-8 z-10"
          >
            <motion.div
              transition={{
                bounce: 0.4,
                duration: 5,
              }}
              variants={appearIn}
              className="pr-32 md:pr-0 break-words text-freeport-blue-base font-semibold font-['Poppins'] uppercase"
            >
              {headline}
            </motion.div>

            <motion.h1
              transition={{
                bounce: 0.4,
                duration: 5,
              }}
              variants={appearIn}
              className="text-freeport-blue-dark leading-none tracking-tighter text-freeport-hero font-bold font-['Poppins'] mt-4"
            >
              {title}
            </motion.h1>

            <motion.div
              transition={{
                bounce: 0.4,
                duration: 5,
              }}
              variants={appearIn}
              className="text-freeport-blue-new leading-7 text-xl mt-6 xl:max-w-sm xl:text-2xl xl:mt-10"
            >
              {description}
            </motion.div>

            <motion.div
              variants={appearIn}
              whileHover={{
                scale: 1.05,
              }}
              className="inline-flex"
            >
              <Link
                href={buttonLink as string}
                scroll={false}
                className="mt-8 px-5 py-3 text-xl inline-block font-bold backface-visibility-hidden whitespace-nowrap transform md:self-center rounded-full bg-freeport-blue-dark text-freeport-blue-light transition hover:scale-105 focus:outline-none active:bg-opacity-80 shadow-sm"
              >
                {buttonText}
              </Link>
            </motion.div>
          </motion.div>

          <div className="absolute right-0 rotate-15 max-w-[50vw] opacity-30 md:opacity-100 transition-opacity -mt-56">
            <div className="flex gap-4 lg:gap-10 xl:gap-12">
              <motion.div
                className="w-full animate-marquee"
                variants={firstColVariant}
                initial="hidden"
                animate="show"
                transition={{ duration: 1, delay: 1 }}
              >
                {firstCol.map((item, index) => (
                  <div key={index} className="min-w-[15rem] max-w-md">
                    <Image
                      src={item.url}
                      width={item.width}
                      height={item.height}
                      alt={item.alt}
                      priority
                      placeholder="empty"
                      className="object-cover max-w-full w-full h-auto mt-4 lg:mt-10 xl:mt-12 shadow-lg bg-white border-[3px] border-white"
                    />
                  </div>
                ))}
              </motion.div>

              <motion.div
                className="w-full animate-marquee-reversed"
                variants={secondColVariant}
                initial="hidden"
                animate="show"
                transition={{ duration: 1, delay: 1 }}
              >
                {secondCol.map((item, index) => (
                  <div key={index} className="min-w-[15rem] max-w-md">
                    <Image
                      src={item.url}
                      width={item.width}
                      height={item.height}
                      alt={item.alt}
                      priority
                      placeholder="empty"
                      className="object-cover max-w-full w-full h-auto mt-4 lg:mt-10 xl:mt-12 shadow-lg bg-white border-[3px] border-white"
                    />
                  </div>
                ))}
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  )
}

HeroSection.defaultProps = {
  headline: 'Invest in physical. Collect in digital.',
  title: 'Fine art investing made simple.',
  description:
    'Purchase SEC-qualified fractional shares backed by historic blue chip masterpieces.',
  buttonText: 'Discover Freeport →',
  buttonLink: '#how-it-works',
}

export { HeroSection }
