import { QuestionMarkCircleSmTransparent } from 'components/icons/app/QuestionMarkCircleSmTransparent'
import { PopoverContent } from 'components/utilities/PopoverContent'
import Link from 'next/link'
import { useState } from 'react'
import { Popover } from 'react-tiny-popover'

const AnnualizedReturnsStat = () => {
  const [showPopover, setShowPopover] = useState(false)

  return (
    <div
      className="mt-12"
      onMouseEnter={() => setShowPopover(true)}
      onMouseLeave={() => setShowPopover(false)}
    >
      <div className="inline-block relative cursor-default text-freeport-blue-new text-freeport-section-title-md font-title">
        8.9%
        <QuestionMarkCircleSmTransparent className="absolute -top-1 -right-8 md:-right-4 lg:-right-4" />
      </div>
      <>
        <Popover
          isOpen={showPopover}
          positions={['bottom']}
          containerClassName="z-20"
          content={
            <PopoverContent>
              <p className="font-medium">
                We got this number ArtMarket®&apos;s Artprice100&copy; index
                from 2000-2022, an index which tracks the sales performance of
                the top 100 artists in the world.
              </p>
              <p className="mt-1">
                Source:{' '}
                <Link
                  href="https://www.artprice.com/artmarketinsight/the-artprice100-index-of-blue-chip-artists-up-3-over-2022"
                  title=""
                  className="font-semibold hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  artprice.com
                </Link>
              </p>
            </PopoverContent>
          }
        >
          <div className="cursor-default text-freeport-blue-base text-sm">
            Annualized returns
          </div>
        </Popover>
      </>
    </div>
  )
}

export { AnnualizedReturnsStat }
