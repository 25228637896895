import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { PostImage } from 'components/blog/Post'

export enum ContentfulFontSize {
  Small = 'text-freeport-sm',
  Base = 'text-freeport-base',
  Blog = 'text-freeport-lg font-normal',
  Medium = 'text-freeport-md',
  Large = 'text-freeport-lg',
  XLarge = 'text-freeport-xl',
}

const leading = (fontSize: ContentfulFontSize) => {
  if (fontSize === ContentfulFontSize.Small) return 'leading-6'
  if (fontSize === ContentfulFontSize.Base) return 'leading-6'
  if (fontSize === ContentfulFontSize.Blog) return ''
  if (fontSize === ContentfulFontSize.Large) return 'leading-6'
  if (fontSize === ContentfulFontSize.XLarge) return 'leading-8'
}

const paragraphMargins = (fontSize: ContentfulFontSize) => {
  if (fontSize === ContentfulFontSize.Blog) return 'mb-4'
  return 'my-4'
}

interface MarkdownOptions {
  postHeroImage?: PostImage
  baseFontSize?: ContentfulFontSize
  headingClassNames?: string
  paragraphClassNames?: string
  boldClassNames?: string
}

const customMarkdownOptions = (
  {
    postHeroImage,
    baseFontSize = ContentfulFontSize.XLarge,
    headingClassNames,
    paragraphClassNames,
    boldClassNames,
  } = {} as MarkdownOptions
) => ({
  renderMark: {
    [MARKS.BOLD]: (text: any) => (
      <strong className={boldClassNames || 'font-semibold'}>{text}</strong>
    ),
    [MARKS.UNDERLINE]: (text: any) => <span className="underline">{text}</span>,
    [MARKS.ITALIC]: (text: any) => <em className="italic">{text}</em>,
    [MARKS.CODE]: (text: any) => <code className="code">{text}</code>,
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node: any, children: any) => (
      <h2
        className={
          headingClassNames ||
          'text-freeport-display-xs text-freeport-gray-900 mt-8 mb-4 font-semibold'
        }
      >
        {children}
      </h2>
    ),
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
      <p
        className={
          paragraphClassNames ||
          `${baseFontSize} ${leading(baseFontSize)} ${paragraphMargins(
            baseFontSize
          )} text-freeport-gray-600`
        }
      >
        {children}
      </p>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node: any, _children: any) => {
      // THIS IS A HACK SO THAT HEADER IMAGES IN BLOG POSTS DON'T END UP IN THE POST BODY
      if (postHeroImage?.src === node.data.target.fields.file.url) return null
      // THIS IS A HACK SO THAT HEADER IMAGES IN BLOG POSTS DON'T END UP IN THE POST BODY
      return (
        <img
          className="my-12 rounded-xl w-full"
          src={`${node.data.target.fields.file.url}`}
          alt={node.data.target.fields.file.fileName}
        />
      )
    },
    [BLOCKS.OL_LIST]: (node: any, children: any) => (
      <ol className="list-decimal my-8 pl-4">{children}</ol>
    ),
    [BLOCKS.UL_LIST]: (node: any, children: any) => (
      <ul className="list-disc my-8 pl-4">{children}</ul>
    ),
  },
  renderText: (text: string) => {
    return text
      .split('\n')
      .flatMap((line, i, arr) =>
        i < arr.length - 1 ? [line, <br key={i} />] : line
      )
  },
})

const renderContentfulDocument = (
  body: any,
  {
    postHeroImage,
    baseFontSize = ContentfulFontSize.XLarge,
    headingClassNames,
    paragraphClassNames,
    boldClassNames,
  }: MarkdownOptions = {}
) => {
  return documentToReactComponents(
    body,
    customMarkdownOptions({
      postHeroImage,
      baseFontSize,
      headingClassNames,
      paragraphClassNames,
      boldClassNames,
    })
  )
}

const generateContentfulText = (texts: any[], nodeType = 'paragraph') => {
  const content = texts.map(({ text, marks = [] }) => {
    return {
      nodeType: 'text',
      value: text.replace('\n', ' \n '),
      marks: marks.map((type: any) => ({ type })),
      data: {},
    }
  })

  return {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType,
        content,
        data: {},
      },
    ],
  }
}

export { renderContentfulDocument, generateContentfulText }
