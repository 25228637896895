import { LAYOUT_CONTAINER_CLASS } from 'components/layout/App/Layout'
import { PublicDisclaimer } from 'components/legal/PublicDisclaimer'
import { AboutPageFootnotes } from './AboutPageFootnotes'
import { FooterLinks } from './FooterLinks'

const SiteFooter = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="w-full relative z-10 bg-freeport-blue-dark text-white font-['Poppins']">
      <div className={`${LAYOUT_CONTAINER_CLASS}`}>
        <FooterLinks />

        <div className="flex justify-center md:justify-end">
          <p className="text-xs text-freeport-md mt-20 md:mt-24 mb-3">
            &copy; {currentYear} Freeport Technologies, Inc. All rights
            reserved.
          </p>
        </div>

        <div className="pt-4 border-t border-white/20">
          <AboutPageFootnotes />
          <PublicDisclaimer />
        </div>
      </div>
    </footer>
  )
}

export { SiteFooter }
