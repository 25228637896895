import { ThemeContextProvider } from 'lib/context/themeContext'
import { ReactElement } from 'react'

import { FreeportLayoutProps } from 'pages/_app'

const CleanLayout = ({ children }: FreeportLayoutProps) => {
  return children
}

const withCleanLayout = (page: ReactElement) => {
  return (
    <ThemeContextProvider>
      <CleanLayout>{page}</CleanLayout>
    </ThemeContextProvider>
  )
}

export { CleanLayout, withCleanLayout }
