import { Container } from 'components/design-system/Container'
import { validateLocalImage } from 'lib/validators/validateLocalImage'
import Image from 'next/image'

interface AsSeeonOnSectionProps {
  title?: string
  logos?: any[]
}

const AsSeeonOnSection = ({ title, logos }: AsSeeonOnSectionProps) => {
  return (
    <section>
      <Container className="py-0 md:py-0">
        <div className="pt-8">
          <ul className="flex flex-wrap justify-center items-center gap-y-4">
            <li className="w-full lg:w-1/6 flex justify-center">
              <h2 className="text-freeport-blue-new text-2xl tracking-tighter">
                {title}
              </h2>
            </li>
            {logos?.map((logo, index) => (
              <li className="w-1/3 lg:w-1/6 flex justify-center" key={index}>
                <Image
                  src={`${validateLocalImage(logo.file.url) ? '' : 'https:'}${
                    logo.file.url
                  }`}
                  height={logo.file.details.image.height}
                  width={logo.file.details.image.width}
                  alt={logo.description}
                  className="md:px-4"
                />
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  )
}

const SEEN_ON = [
  {
    description: 'Yahoo',
    file: {
      url: '/images/seen-on/yahoo-logo.png',
      details: {
        image: {
          width: 1600,
          height: 586,
        },
      },
    },
  },
  {
    description: 'Coindesk',
    file: {
      url: '/images/seen-on/coindesk-logo.png',
      details: {
        image: {
          width: 2600,
          height: 1600,
        },
      },
    },
  },
  {
    description: 'Nasdaq',
    file: {
      url: '/images/seen-on/nasdaq-logo.png',
      details: {
        image: {
          width: 2560,
          height: 727,
        },
      },
    },
  },
  {
    description: 'Forbes',
    file: {
      url: '/images/seen-on/forbes-logo.png',
      details: {
        image: {
          width: 1500,
          height: 664,
        },
      },
    },
  },
  {
    description: 'Axios',
    file: {
      url: '/images/seen-on/axios-logo.png',
      details: {
        image: {
          width: 2560,
          height: 649,
        },
      },
    },
  },
]

AsSeeonOnSection.defaultProps = {
  title: 'As seen on',
  logos: SEEN_ON,
}

export { AsSeeonOnSection }
