import { useRouter } from 'next/router'

const AboutPageFootnotes = () => {
  const isAboutPage = useRouter().pathname.slice(0, 6) === '/about'

  return !isAboutPage ? null : (
    <div className="mt-8">
      <p className="text-freeport-xs">
        &#42; There is no guarantee a secondary market will develop or even if
        it does, will have ample liquidity.
      </p>
      <p className="text-freeport-xs">
        &#42;&#42; A return of original capital contributions and profits are
        not guaranteed and are dependent on the artwork appreciating and
        Freeport being able to find a buyer.
      </p>
    </div>
  )
}

export { AboutPageFootnotes }
