import { Container } from 'components/design-system/Container'
import { motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { appearIn, slideLeft } from './framer'
import { validateLocalImage } from 'lib/validators/validateLocalImage'

interface JamesDeanFeaturedProps {
  title?: string
  description?: string
  ctaLinkText?: string
  ctaLinkUrl?: string
  image?: any
}

const JamesDeanFeatured = ({
  title,
  description,
  ctaLinkText,
  ctaLinkUrl,
  image,
}: JamesDeanFeaturedProps) => {
  return (
    <section className="overflow-hidden bg-freeport-blue-200">
      <Container className="mt-14 pt-28 md:pt-36">
        <motion.div
          transition={{
            staggerChildren: 0.1,
            delayChildren: 0.3,
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: '-200px 0px' }}
          className="grid md:grid-cols-2 gap-4 items-center"
        >
          <motion.div
            variants={slideLeft}
            viewport={{ margin: '-200px 0px' }}
            className="order-2 md:order-1 -ml-40 md:-ml-96 -mr-20 pr-16 relative"
          >
            <Image
              src={`${validateLocalImage(image.file.url) ? '' : 'https:'}${
                image.file.url
              }`}
              height={image.file.details.image.height}
              width={image.file.details.image.width}
              alt={image.description}
            />
          </motion.div>

          <div className="order-1 md:order-2 mb-20">
            <motion.h2
              variants={appearIn}
              className="text-freeport-blue-new font-title text-freeport-section-title-md mt-4"
            >
              {title}
            </motion.h2>

            <motion.p
              variants={appearIn}
              className="text-freeport-blue-navy mt-4 text-xl"
            >
              {description}
            </motion.p>

            <motion.p variants={appearIn}>
              <Link
                href={ctaLinkUrl as string}
                className="mt-8 px-5 py-3 inline-block font-bold backface-visibility-hidden whitespace-nowrap transform md:self-center rounded-full bg-freeport-blue-dark text-freeport-blue-light transition hover:scale-105 focus:outline-none active:bg-opacity-80 shadow-sm"
              >
                {ctaLinkText}
              </Link>
            </motion.p>
          </div>
        </motion.div>
      </Container>
    </section>
  )
}

JamesDeanFeatured.defaultProps = {
  title: 'Fine art investing meets new age collecting',
  description:
    'Freeport shares are issued under Reg A, providing equity ownership that’s backed by valuable fine art. They’re also represented by tokens on the Ethereum blockchain - paving the way for enhanced utility as the technology evolves.',
  ctaLinkText: 'Start investing in fine art today →',
  ctaLinkUrl: '/discover',
  image: {
    description: '"Rebel Without a Cause (1985)" by Andy Warhol',
    file: {
      url: '/images/marketing/artwork/james-dean-metrics.png',
      details: {
        image: {
          width: 1332,
          height: 2142,
        },
      },
    },
  },
}

export { JamesDeanFeatured }
