import { QuestionMarkCircleSmTransparent } from 'components/icons/app/QuestionMarkCircleSmTransparent'
import { PopoverContent } from 'components/utilities/PopoverContent'
import Link from 'next/link'
import { useState } from 'react'
import { Popover } from 'react-tiny-popover'

const SandPStatReturns = () => {
  const [showPopover, setShowPopover] = useState(false)

  return (
    <div
      className="mt-12"
      onMouseEnter={() => setShowPopover(true)}
      onMouseLeave={() => setShowPopover(false)}
    >
      <div className="inline-block relative cursor-default text-freeport-blue-new text-freeport-section-title-md font-title">
        +40.4%
        <QuestionMarkCircleSmTransparent className="absolute -top-1 -right-8 md:-right-4 lg:-right-4" />
      </div>
      <div>
        <Popover
          isOpen={showPopover}
          positions={['bottom']}
          containerClassName="z-20"
          content={
            <PopoverContent>
              <p className="font-medium">
                We got this number comparing ArtMarket®&apos;s Artprice100&copy;
                index from 2000-2022, to total returns from the S&P 500 index
                during the same time period. S&P 500 index is represented by
                (^SP500TR).
              </p>
              <p className="mt-3">
                Source:{' '}
                <Link
                  href="https://finance.yahoo.com/quote/%5ESP500TR/"
                  title=""
                  className="font-semibold hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Yahoo! Finance
                </Link>
              </p>
            </PopoverContent>
          }
        >
          <div className="cursor-default text-freeport-blue-base text-sm">
            Over S&amp;P500 returns
          </div>
        </Popover>
      </div>
    </div>
  )
}

export { SandPStatReturns }
