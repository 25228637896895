import Link from 'next/link'

interface Link {
  href: string
  text: string
}

interface FooterLinkListProps {
  title: string
  links: Link[]
  classNames?: string
}

const FooterLinkList = ({ title, links, classNames }: FooterLinkListProps) => {
  return (
    <div className={classNames}>
      <h3 className="text-freeport-sm tracking-tighter font-bold text-[1.375rem] mb-12 md:pt-2">
        {title}
      </h3>

      {links?.length && (
        <ul className={`flex flex-col gap-y-3`}>
          {links?.map(({ href, text }: Link) => (
            <li key={href}>
              <Link
                href={href}
                className="text-sm hover:text-landing-page-gray-muted"
              >
                {text}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export { FooterLinkList }
