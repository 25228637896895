import classNames from 'classnames'

interface GalleryVideoProps {
  src: string
  poster?: string
  ariaLabel?: string
  className?: string
}

const GalleryVideo = ({
  src,
  poster,
  ariaLabel,
  className,
}: GalleryVideoProps) => {
  return (
    <div
      className={classNames(
        'absolute z-[-1] -left-80 md:-left-[30vw] inset-0 overflow-hidden',
        className
      )}
    >
      <div
        className={classNames(
          'absolute inset-0 rounded-[2rem] overflow-hidden bg-gradient-to-r from-freeport-blue-new/70 to-black/20',
          className
        )}
      />

      <video
        autoPlay
        loop
        playsInline
        disablePictureInPicture
        muted
        poster={poster}
        aria-label={ariaLabel}
        className="aspect-video object-cover h-full w-full"
      >
        <source src={src} type="video/mp4" />
        <p>
          Your browser doesn&apos;t support HTML video. Here is a
          <a href={src}>link to the video</a> instead.
        </p>
      </video>
    </div>
  )
}

export { GalleryVideo }
