import { SocialMediaLinks } from 'components/design-system/SocialMediaLinks'
import { FreeportLogo } from 'components/icons/freeport/logo'
import { FooterLinkList } from './FooterLinkList'

const FooterLinks = () => {
  const PRODUCT_LINKS = [
    {
      text: 'Gallery',
      href: '/gallery',
    },
    {
      text: 'How It Works',
      href: '/about',
    },
    {
      text: 'FAQ',
      href: '/faq',
    },
  ]

  const RESOURCES_LINKS = [
    {
      text: 'Blog',
      href: '/blog',
    },
    {
      text: 'Press',
      href: '/press',
    },
    {
      text: 'Privacy',
      href: '/privacy',
    },
    {
      text: 'Terms',
      href: '/terms',
    },
    {
      text: 'Disclaimer',
      href: '/legal/disclaimer',
    },
  ]

  const COMPANY_LINKS = [
    {
      text: 'Team',
      href: '/team',
    },
    {
      text: 'Contact',
      href: '/contact',
    },
    {
      text: 'Jobs',
      href: '/jobs',
    },
  ]

  return (
    <div className="pt-20 grid md:grid-cols-5 gap-12 md:gap-8">
      <div>
        <FreeportLogo className="h-8 w-8" variant="logo-type" />

        <p className="mt-4 text-freeport-md font-bold text-sm">
          Ownership for all&trade;
        </p>
      </div>

      <FooterLinkList title="Product" links={PRODUCT_LINKS} />
      <FooterLinkList title="Resources" links={RESOURCES_LINKS} />
      <FooterLinkList title="Company" links={COMPANY_LINKS} />

      <div>
        <h3 className="text-freeport-sm tracking-tighter font-bold text-[1.375rem] mb-12 md:pt-2">
          Social
        </h3>

        <SocialMediaLinks
          classNames="md:mt-auto"
          innerLinksClassNames="hover:text-landing-page-gray-muted"
        />
      </div>
    </div>
  )
}

export { FooterLinks }
