const pirsch = (event: string, meta?: Record<string, any>) => {
  if (typeof window === 'undefined') return

  const { pirsch } = window as any
  if (!pirsch) return

  pirsch(event, { meta })
}

export { pirsch }
