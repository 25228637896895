import { Container } from 'components/design-system/Container'
import MarketChart from 'components/marketing/charts/market'
import {
  generateContentfulText,
  renderContentfulDocument,
} from 'lib/clients/contentful/document'
import { OFFERING_CIRCULAR } from 'lib/constants'
import Link from 'next/link'
import { AnnualizedReturnsStat } from 'views/home/MetricsSection/AnnualizedReturnsStat'
import { SandPStatReturns } from 'views/home/MetricsSection/SandPStatReturns'
import { WarholPriceStat } from 'views/home/MetricsSection/WarholPriceStat'

interface MetricsSectionProps {
  title?: string
  description?: string
  chartTitle?: string
  chartDescription?: string
  chartDisclaimer?: string
  ctaLinkText?: string
  ctaLinkUrl?: string
  ctaText?: string
}

const MetricsSection = ({
  title,
  description,
  chartTitle,
  chartDescription,
  chartDisclaimer,
  ctaLinkText,
  ctaLinkUrl,
  ctaText,
}: MetricsSectionProps) => {
  return (
    <section className="bg-freeport-blue-light">
      <Container>
        <div className="flex justify-center my-8 lg:mt-16 lg:mb-12">
          <div className="text-center">
            {renderContentfulDocument(title, {
              headingClassNames:
                'text-freeport-blue-new font-title text-4xl md:text-5xl leading-tight font-bold tracking-tight mb-6',
              boldClassNames: 'font-bold',
            })}
            <p className="text-freeport-blue-navy text-xl max-w-prose">
              {description}
            </p>
          </div>
        </div>
        <div className="bg-white p-8 lg:p-16 rounded-[2rem]">
          <h3 className="text-freeport-blue-new font-title font-bold text-2xl md:text-3xl mb-2.5 tracking-tighter">
            {chartTitle}
          </h3>
          <p className="text-freeport-blue-navy mb-2.5">{chartDescription}</p>
          <p className="text-xs text-freeport-blue-navy italic">
            {chartDisclaimer}
          </p>

          <div className="flex flex-wrap">
            <div className="order-2 lg:order-1 w-full lg:w-2/6 lg:px-10 text-center">
              <AnnualizedReturnsStat />
              <SandPStatReturns />
              <WarholPriceStat />
            </div>

            <div className="order-1 lg:order-2 w-full lg:w-4/6 flex items-center justify-center">
              <div className="flex flex-col w-full mt-8 lg:mt-0 lg:px-8">
                <ul className="flex mx-auto lg:ml-auto lg:mr-0">
                  <li className="flex items-center text-sm tracking-tighter">
                    <span className="inline-block w-4 h-4 rounded-full bg-freeport-blue-base mr-2"></span>
                    Artprice100 &copy;
                  </li>

                  <li className="flex items-center text-sm tracking-tighter ml-10">
                    <span className="inline-block w-4 h-4 rounded-full bg-freeport-mustard mr-2"></span>
                    S&amp;P 500
                  </li>
                </ul>

                <div className="h-72">
                  <MarketChart />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 mb-12">
          <p className="text-center text-freeport-blue-new">
            {ctaText}
            <Link
              href={ctaLinkUrl as string}
              className="text-freeport-blue-base border-b-2 border-freeport-blue-base hover:border-transparent transition-colors"
            >
              {ctaLinkText}
            </Link>
          </p>
        </div>
      </Container>
    </section>
  )
}

MetricsSection.defaultProps = {
  title: generateContentfulText(
    [{ text: 'Major artwork investing.\nFractional prices.' }],
    'heading-2'
  ),
  description:
    'Discover the potential benefits of investing in fractionalized fine art with annualized returns consistently outperforming the S&P since 2000.',
  chartTitle: 'Performance vs. stock market',
  chartDescription:
    'Since January 2000 the Artprice100© has consistently outperformed the S&P, especially during down markets.',
  chartDisclaimer: 'Past performance does not guarantee future results',
  ctaText: 'All Freeport offerings have been SEC qualified. ',
  ctaLinkText: 'See our offering circular →',
  ctaLinkUrl: OFFERING_CIRCULAR,
}

export { MetricsSection }
