import { Variants } from 'framer-motion'

const appearIn: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 24,
      duration: 2,
      staggerChildren: 0.1,
    }
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 2,
      staggerChildren: 0.1,
    }
  }
}

const slideUp: Variants = {
  visible: {
    top: 0,
    transition: {
      type: 'spring',
      duration: 1,
    }
  },
  hidden: {
    top: 50,
  }
}

const slideLeft: Variants = {
  visible: {
    left: 0,
    transition: {
      type: 'spring',
      duration: 1,
    }
  },
  hidden: {
    left: -50,
  }
}

const firstColVariant: Variants = {
  show: {
    opacity: 1,
    x: 0,
    y: 0,
  },
  hidden: {
    opacity: 0,
    x: -5,
    y: -5,
  },
}

const secondColVariant: Variants = {
  show: {
    opacity: 1,
    x: 0,
    y: 0,
  },
  hidden: {
    opacity: 0,
    x: 5,
    y: 5,
  },
}

export { appearIn, slideUp, slideLeft, firstColVariant, secondColVariant }
