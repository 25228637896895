import { AxisBottom, AxisLeft } from '@visx/axis'
import { curveNatural } from '@visx/curve'
import { Group } from '@visx/group'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { scaleLinear } from '@visx/scale'
import { LinePath } from '@visx/shape'

type ChartProps = {
  width: number
  height: number
}

const X_SCALE = 24
const Y_SCALE = 750

type Point = {
  x: number
  y: number
}

type MarketDataProps = {
  title: string
  key: string
  color: string
  data: Point[]
}

const data: MarketDataProps[] = [
  {
    title: 'S&P 500',
    key: 'sp500',
    color: '#eab308',
    data: [
      { x: 0, y: 100 },
      { x: 1, y: 89 },
      { x: 2, y: 81 },
      { x: 3, y: 65 },
      { x: 4, y: 81 },
      { x: 5, y: 89 },
      { x: 6, y: 96 },
      { x: 7, y: 109 },
      { x: 8, y: 114 },
      { x: 9, y: 75 },
      { x: 10, y: 93 },
      { x: 11, y: 106 },
      { x: 12, y: 108 },
      { x: 13, y: 125 },
      { x: 14, y: 165 },
      { x: 15, y: 189 },
      { x: 16, y: 190 },
      { x: 17, y: 215 },
      { x: 18, y: 261 },
      { x: 19, y: 246 },
      { x: 20, y: 329 },
      { x: 21, y: 388 },
      { x: 22, y: 500 },
      { x: 23, y: 410 },
    ],
  },
  // {
  //   title: 'Dow Jones Industrial Average',
  //   key: 'DJIA',
  //   color: '#53389E',
  //   data: [
  //     { x: 0, y: 0 },
  //     { x: 1, y: 5 },
  //     { x: 2, y: 7 },
  //     { x: 3, y: 5 },
  //     { x: 4, y: 6 },
  //     { x: 5, y: 9 },
  //     { x: 6, y: 8 },
  //     { x: 7, y: 7 },
  //     { x: 8, y: 9 },
  //     { x: 9, y: 7 },
  //     { x: 10, y: 7 },
  //   ],
  // },
  {
    title: 'Artprice 100©',
    key: 'ART',
    color: '#175cd3',
    data: [
      { x: 0, y: 100 },
      { x: 1, y: 111 },
      { x: 2, y: 111 },
      { x: 3, y: 122 },
      { x: 4, y: 135 },
      { x: 5, y: 164 },
      { x: 6, y: 179 },
      { x: 7, y: 231 },
      { x: 8, y: 320 },
      { x: 9, y: 281 },
      { x: 10, y: 235 },
      { x: 11, y: 303 },
      { x: 12, y: 355 },
      { x: 13, y: 334 },
      { x: 14, y: 385 },
      { x: 15, y: 452 },
      { x: 16, y: 489 },
      { x: 17, y: 397 },
      { x: 18, y: 460 },
      { x: 19, y: 480 },
      { x: 20, y: 496 },
      { x: 21, y: 505 },
      { x: 22, y: 689 },
      { x: 23, y: 709 },
    ],
  },
]

const CHART_GAP = 50

const getX = (width: number, x: number) => {
  const svgWidth = width - CHART_GAP * 2
  return (svgWidth / (X_SCALE - 1)) * x
}

const getY = (height: number, y: number) => {
  const svgHeight = height - CHART_GAP * 2
  return svgHeight - (svgHeight / Y_SCALE) * y
}

const yAxisTickLabelProps = () =>
  ({
    fill: '#364152',
    fontSize: 12,
    fontFamily: 'Poppins, sans-serif',
    textAnchor: 'end',
    dy: 4,
  } as const)

const xAxisTickLabelProps = () =>
  ({
    fill: '#364152',
    fontSize: 12,
    fontFamily: 'Poppins, sans-serif',
    textAnchor: 'middle',
  } as const)

const Chart = ({ width, height }: ChartProps) => {
  const chartWidth = width - CHART_GAP * 2
  const chartHeight = height - CHART_GAP * 2

  return (
    <figure className="w-full h-full">
      <svg width={width} height={height}>
        {data.map((datum, i) => {
          return (
            <Group key={`chart-line-${datum.key}`} top={0} left={0}>
              <Group key={`line-${datum.key}`} top={CHART_GAP} left={CHART_GAP}>
                <LinePath<Point>
                  curve={curveNatural}
                  data={datum.data}
                  x={(point) => getX(width, point.x)}
                  y={(point) => getY(height, point.y)}
                  stroke={datum.color}
                  strokeWidth={2 * (i + 1)}
                  strokeOpacity={1}
                  shapeRendering="geometricPrecision"
                />
              </Group>
              <AxisLeft
                key={`axis-y-${datum.key}`}
                scale={scaleLinear({
                  domain: [750, 0],
                  range: [CHART_GAP, chartHeight + CHART_GAP],
                })}
                numTicks={5}
                label="y-axis"
                tickFormat={(value) => `${value}%`}
                hideTicks={true}
                hideAxisLine={true}
                tickLabelProps={yAxisTickLabelProps}
                left={40}
              />
              <AxisBottom
                key={`axis-x-${datum.key}`}
                scale={scaleLinear({
                  domain: [2000, 2023],
                  range: [CHART_GAP, width - CHART_GAP],
                })}
                label="x-axis"
                numTicks={6}
                tickFormat={(value) => `${value}`}
                left={0}
                hideTicks={true}
                hideAxisLine={true}
                tickLabelProps={xAxisTickLabelProps}
                top={height - 30}
              />
            </Group>
          )
        })}
      </svg>
      <figcaption></figcaption>
    </figure>
  )
}

const MarketChart = () => {
  return (
    <ParentSize>
      {({ width, height }) => <Chart width={width} height={height} />}
    </ParentSize>
  )
}

export default MarketChart
