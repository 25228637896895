import { validateLocalImage } from 'lib/validators/validateLocalImage'
import { GalleryCard } from './GalleryCard'
import { WarholCollectionSectionBg } from './WarholCollectionSectionBg'

interface WarholCollectionSectionProps {
  title?: string
  description?: string
  artworkContent?: any[]
}

const WarholCollectionSection = ({
  title,
  description,
  artworkContent,
}: WarholCollectionSectionProps) => {
  return (
    <div className="md:mt-24 lg:mt-36">
      <WarholCollectionSectionBg className="w-full mx-auto" />

      <div className="-mt-32 -mb-40">
        <h3 className="text-freeport-section-title-sm font-title">{title}</h3>

        <p className="text-lg mt-2">{description}</p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-10">
          {artworkContent?.map((item, index) => (
            <GalleryCard
              key={index}
              image={{
                src: `${
                  validateLocalImage(item.image.file.url) ? '' : 'https:'
                }${item.image.file.url}`,
                width: item.image.file.details.image.width,
                height: item.image.file.details.image.height,
                alt: item.image.description,
              }}
              artist={item.artist}
              title={item.title}
              description={item.description}
              spots={item.spots}
              slug={item.slug}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const COLLECTION = [
  {
    image: {
      description: '"Marilyn (1967)" by Andy Warhol',
      file: {
        url: '/images/marketing/artwork/marilyn-header.jpg',
        details: {
          image: {
            width: 838,
            height: 838,
          },
        },
      },
    },
    artist: 'Andy Warhol',
    title: 'Marilyn (1967)',
    description: 'Starting under $55 per share (min 10)',
    spots: '1,000 spots available ',
    slug: 'warhol-001-marilyn',
  },
  {
    image: {
      description: '"Double Mickey (1981)" by Andy Warhol',
      file: {
        url: '/images/marketing/artwork/double-mickey-header.jpg',
        details: {
          image: {
            width: 835,
            height: 592,
          },
        },
      },
    },
    artist: 'Andy Warhol',
    title: 'Double Mickey (1981)',
    description: 'Starting under $80 per share (min 10)',
    spots: '1,000 spots available ',
    slug: 'warhol-001-double-mickey',
  },
  {
    image: {
      description: '"Mick Jagger (1967)" by Andy Warhol',
      file: {
        url: '/images/marketing/artwork/mick-jagger-header.jpg',
        details: {
          image: {
            width: 839,
            height: 1247,
          },
        },
      },
    },
    artist: 'Andy Warhol',
    title: 'Mick Jagger (1967)',
    description: 'Starting under $25 per share (min 10)',
    spots: '1,000 spots available ',
    slug: 'warhol-001-mick-jagger',
  },
  {
    image: {
      description: '"Rebel Without a Cause (1985)" by Andy Warhol',
      file: {
        url: '/images/marketing/artwork/rebel-without-a-cause-header.jpg',
        details: {
          image: {
            width: 838,
            height: 838,
          },
        },
      },
    },
    artist: 'Andy Warhol',
    title: 'Rebel Without a Cause',
    description: 'Starting under $20 per share (min 10)',
    spots: '1,000 spots available ',
    slug: 'warhol-001-rebel-without-a-cause',
  },
]

WarholCollectionSection.defaultProps = {
  title: 'Be a part of our first Warhol collection',
  description:
    'Access some of the most iconic pieces from the king of American pop art culture',
  artworkContent: COLLECTION,
}
export { WarholCollectionSection }
