import { Container } from 'components/design-system/Container'
import { motion } from 'framer-motion'
import { validateLocalImage } from 'lib/validators/validateLocalImage'
import Image from 'next/image'
import Link from 'next/link'
import { appearIn, slideUp } from './framer'

interface MarilynFeaturedProps {
  title?: string
  description?: string
  ctaLinkText?: string
  ctaLinkUrl?: string
  image?: any
}

const MarilynFeatured = ({
  title,
  description,
  ctaLinkText,
  ctaLinkUrl,
  image,
}: MarilynFeaturedProps) => {
  return (
    <section className="overflow-hidden">
      <Container className="mt-24 md:pt-24 -mb-24 md:-mb-48">
        <motion.div
          transition={{
            staggerChildren: 0.1,
            delayChildren: 0.2,
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: '-200px 0px' }}
          className="grid md:grid-cols-2 gap-4"
        >
          <motion.div
            variants={slideUp}
            viewport={{ margin: '-200px 0px' }}
            className="relative order-2 md:order-1 -rotate-15 -ml-40 md:-ml-56 pr-16"
          >
            <Image
              src={`${validateLocalImage(image.file.url) ? '' : 'https:'}${
                image.file.url
              }`}
              height={image.file.details.image.height}
              width={image.file.details.image.width}
              alt={image.description}
              className="shadow-art-work"
            />
          </motion.div>

          <div className="order-1 md:order-2 mb-40 md:mb-0">
            <motion.h2
              variants={appearIn}
              className="text-freeport-blue-new leading-tight text-[2.375rem] md:text-5xl md:leading-tight font-bold font-title mt-4"
            >
              {title}
            </motion.h2>

            <motion.p
              variants={appearIn}
              className="relative text-freeport-blue-navy my-4 text-xl"
            >
              {description}
            </motion.p>

            <motion.p variants={appearIn}>
              <Link
                href={ctaLinkUrl as string}
                className="mt-4 px-5 py-3 inline-block font-bold backface-visibility-hidden whitespace-nowrap transform md:self-center rounded-full bg-freeport-blue-dark text-freeport-blue-light transition hover:scale-105 focus:outline-none active:bg-opacity-80 shadow-sm"
              >
                {ctaLinkText}
              </Link>
            </motion.p>
          </div>
        </motion.div>
      </Container>
    </section>
  )
}

MarilynFeatured.defaultProps = {
  title: 'Expertly curated art with proven origins.',
  description:
    'Each artwork in our collection comes with detailed information about its sourcing. Browse our selection with confidence, knowing that each piece has been carefully vetted for authenticity and quality.',
  ctaLinkText: 'Start building your art portfolio now →',
  ctaLinkUrl: '/discover',
  image: {
    description: '"Marilyn (1967)" by Andy Warhol',
    file: {
      url: '/images/marketing/artwork/marilyn-frame.jpg',
      details: {
        image: {
          width: 1758,
          height: 1756,
        },
      },
    },
  },
}

export { MarilynFeatured }
